<template>
  <header :class="{ 'header-index': whiteHeader }">
    <div class="container">
      <div class="header-inner display--flex">
        <router-link to="/">
          <div class="logo">
            <img
              v-if="whiteHeader || mobileOpen"
              src="@/assets/images/logo.svg"
              alt="WAY2WEI"
            />
            <img v-else src="@/assets/images/logo-b.svg" alt="WAY2WEI" />
          </div>
        </router-link>
        <nav class="display--flex">
          <div class="account display--flex">
            <img
              id="specialButton"
              class="not-mobile-special"
              style="cursor: pointer"
              :src="
                !whiteHeader
                  ? require('@/assets/images/icons/eye-icon-g.png')
                  : require('@/assets/images/icons/eye-icon-w.png')
              "
              alt="ВЕРСИЯ ДЛЯ СЛАБОВИДЯЩИХ"
              title="ВЕРСИЯ ДЛЯ СЛАБОВИДЯЩИХ"
            />
            <a :href="cabinetUrl" class="account-link display--flex">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="24px"
                height="24px"
                viewBox="0 0 18 18"
                fill="currentColor"
              >
                <path
                  d="M9,0C4,0,0,4,0,9c0,5,4,9,9,9c5,0,9-4,9-9C18,4,14,0,9,0z M4,15.2C4.9,13.3,6.8,12,9,12c2.2,0,4.1,1.3,5,3.2
                            c-1.4,1.1-3.1,1.8-5,1.8C7.1,17,5.4,16.3,4,15.2z M6,8c0-1.7,1.3-3,3-3s3,1.3,3,3s-1.3,3-3,3S6,9.7,6,8z M14.8,14.5
                            c-0.8-1.5-2.1-2.6-3.6-3.2c1.1-0.7,1.9-2,1.9-3.4c0-2.2-1.8-4-4-4C6.8,4,5,5.8,5,8c0,1.4,0.7,2.7,1.9,3.4C5.3,11.9,4,13,3.2,14.5
                            C1.9,13.1,1,11.1,1,9c0-4.4,3.6-8,8-8s8,3.6,8,8C17,11.1,16.1,13.1,14.8,14.5z"
                />
              </svg>
              Мой кабинет
            </a>
            <router-link to="/cart" class="account-cart display--flex">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 20 20"
                width="20px"
                height="24px"
                fill="currentColor"
              >
                <path
                  d="M12.3,19.1c-0.4,0-0.8-0.1-1.1-0.4c-0.3-0.2-0.6-0.6-0.8-1c-0.2-0.4-0.2-0.8-0.1-1.2c0.1-0.4,0.3-0.8,0.6-1.1
                                c0.3-0.3,0.7-0.5,1.1-0.6c0.4-0.1,0.8,0,1.2,0.1c0.4,0.2,0.7,0.4,0.9,0.8c0.2,0.3,0.3,0.7,0.3,1.1h0l0,0.1c0,0.6-0.2,1.1-0.6,1.5
                                C13.4,18.9,12.9,19.1,12.3,19.1z M4.5,19.1c-0.4,0-0.8-0.1-1.1-0.4c-0.3-0.2-0.6-0.6-0.8-1S2.4,17,2.5,16.5
                                c0.1-0.4,0.3-0.8,0.6-1.1c0.3-0.3,0.7-0.5,1.1-0.6c0.4-0.1,0.8,0,1.2,0.1c0.4,0.2,0.7,0.4,0.9,0.8c0.2,0.4,0.3,0.8,0.3,1.2l0,0
                                c0,0.6-0.2,1.1-0.6,1.5C5.6,18.9,5.1,19.1,4.5,19.1z M4.5,15.8c-0.2,0-0.4,0.1-0.6,0.2s-0.3,0.3-0.4,0.5S3.4,17,3.5,17.2
                                c0,0.2,0.1,0.4,0.3,0.6c0.1,0.2,0.3,0.3,0.5,0.3c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.2,0.5-0.4c0.1-0.2,0.2-0.4,0.2-0.6
                                c0-0.3-0.1-0.6-0.3-0.8C5.1,15.9,4.8,15.8,4.5,15.8z M12.3,15.8c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.3,0.3-0.4,0.5s-0.1,0.4-0.1,0.7
                                c0,0.2,0.1,0.4,0.3,0.6c0.2,0.2,0.3,0.3,0.5,0.3c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.2,0.5-0.4c0.1-0.2,0.2-0.4,0.2-0.6
                                c0-0.3-0.1-0.6-0.3-0.8C12.9,15.9,12.6,15.8,12.3,15.8z M12,14.2H3c-0.1,0-0.3-0.1-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.4
                                c0-0.1,0.1-0.3,0.1-0.4c0.1-0.1,0.2-0.1,0.3-0.1h9c0.4,0,0.8-0.2,1.2-0.4c0.3-0.3,0.6-0.7,0.6-1.1l0.1-0.7H3.6
                                c-0.5,0-1-0.2-1.4-0.5C1.8,10,1.6,9.6,1.5,9L0.9,3.9c0-0.1,0-0.1,0-0.2C0.9,3.6,1,3.6,1,3.5c0-0.1,0.1-0.1,0.2-0.1
                                c0.1,0,0.1,0,0.2,0h14l0.4-2c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.1,0.3-0.1h2.3c0.1,0,0.3,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.1,0.4
                                c0,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.2,0.2-0.3,0.2h-1.9l-1.9,9.9c-0.1,0.7-0.5,1.3-1,1.7C13.3,14,12.6,14.2,12,14.2z M3.6,9.9h10.6
                                l1.1-5.5H1.9l0.5,4.5c0,0.3,0.2,0.5,0.4,0.7C3,9.8,3.3,9.9,3.6,9.9L3.6,9.9z"
                />
              </svg>
              <span
                v-if="countProducts"
                :class="countProducts ? 'basket-count' : 'no-item'"
                >{{ countProducts }}</span
              >
            </router-link>
          </div>
          <ul class="header-menu display--flex">
            <li>
              <router-link to="/products/all">Продукты</router-link>
            </li>
            <li class="has-submenu">
              <router-link to="/contacts">О нас</router-link>
              <div class="submenu">
                <ul>
                  <li><router-link to="/methodology">Методика</router-link></li>
                  <li><router-link to="/contacts">Кто мы</router-link></li>
                </ul>
              </div>
            </li>
            <li><router-link to="/diagnostic">Диагностика</router-link></li>
            <li><router-link to="/geography">География</router-link></li>
            <li><router-link to="/media-center">Медиа-центр</router-link></li>
          </ul>
        </nav>

        <div class="mobile-btns">
          <img
            @click="clickOnSpecial"
            class="mobile-special"
            style="cursor: pointer"
            :src="
              !whiteHeader && !mobileOpen
                ? require('@/assets/images/icons/eye-icon-g.png')
                : require('@/assets/images/icons/eye-icon-w.png')
            "
            alt="ВЕРСИЯ ДЛЯ СЛАБОВИДЯЩИХ"
            title="ВЕРСИЯ ДЛЯ СЛАБОВИДЯЩИХ"
          />

          <button
            type="button"
            @click="openMobileMenu"
            :class="{
              'mobile-toggle-b': !whiteHeader && !mobileOpen,
              active: mobileOpen,
            }"
            class="mobile-toggle"
            id="mobile-toggle"
          ></button>
        </div>
      </div>
    </div>

    <div v-if="fixedMenuState">
      <div class="header-inner display--flex header-inner__fixed">
        <div class="container">
          <router-link to="/">
            <div class="logo">
              <img src="@/assets/images/logo-b.svg" alt="WAY2WEI" />
            </div>
          </router-link>
          <nav class="display--flex">
            <div class="account display--flex">
              <a :href="cabinetUrl" class="account-link display--flex">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="24px"
                  height="24px"
                  viewBox="0 0 18 18"
                  fill="currentColor"
                >
                  <path
                    d="M9,0C4,0,0,4,0,9c0,5,4,9,9,9c5,0,9-4,9-9C18,4,14,0,9,0z M4,15.2C4.9,13.3,6.8,12,9,12c2.2,0,4.1,1.3,5,3.2
                            c-1.4,1.1-3.1,1.8-5,1.8C7.1,17,5.4,16.3,4,15.2z M6,8c0-1.7,1.3-3,3-3s3,1.3,3,3s-1.3,3-3,3S6,9.7,6,8z M14.8,14.5
                            c-0.8-1.5-2.1-2.6-3.6-3.2c1.1-0.7,1.9-2,1.9-3.4c0-2.2-1.8-4-4-4C6.8,4,5,5.8,5,8c0,1.4,0.7,2.7,1.9,3.4C5.3,11.9,4,13,3.2,14.5
                            C1.9,13.1,1,11.1,1,9c0-4.4,3.6-8,8-8s8,3.6,8,8C17,11.1,16.1,13.1,14.8,14.5z"
                  />
                </svg>
                Мой кабинет
              </a>
              <router-link to="/cart" class="account-cart display--flex">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 20 20"
                  width="20px"
                  height="24px"
                  fill="currentColor"
                >
                  <path
                    d="M12.3,19.1c-0.4,0-0.8-0.1-1.1-0.4c-0.3-0.2-0.6-0.6-0.8-1c-0.2-0.4-0.2-0.8-0.1-1.2c0.1-0.4,0.3-0.8,0.6-1.1
                                c0.3-0.3,0.7-0.5,1.1-0.6c0.4-0.1,0.8,0,1.2,0.1c0.4,0.2,0.7,0.4,0.9,0.8c0.2,0.3,0.3,0.7,0.3,1.1h0l0,0.1c0,0.6-0.2,1.1-0.6,1.5
                                C13.4,18.9,12.9,19.1,12.3,19.1z M4.5,19.1c-0.4,0-0.8-0.1-1.1-0.4c-0.3-0.2-0.6-0.6-0.8-1S2.4,17,2.5,16.5
                                c0.1-0.4,0.3-0.8,0.6-1.1c0.3-0.3,0.7-0.5,1.1-0.6c0.4-0.1,0.8,0,1.2,0.1c0.4,0.2,0.7,0.4,0.9,0.8c0.2,0.4,0.3,0.8,0.3,1.2l0,0
                                c0,0.6-0.2,1.1-0.6,1.5C5.6,18.9,5.1,19.1,4.5,19.1z M4.5,15.8c-0.2,0-0.4,0.1-0.6,0.2s-0.3,0.3-0.4,0.5S3.4,17,3.5,17.2
                                c0,0.2,0.1,0.4,0.3,0.6c0.1,0.2,0.3,0.3,0.5,0.3c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.2,0.5-0.4c0.1-0.2,0.2-0.4,0.2-0.6
                                c0-0.3-0.1-0.6-0.3-0.8C5.1,15.9,4.8,15.8,4.5,15.8z M12.3,15.8c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.3,0.3-0.4,0.5s-0.1,0.4-0.1,0.7
                                c0,0.2,0.1,0.4,0.3,0.6c0.2,0.2,0.3,0.3,0.5,0.3c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.2,0.5-0.4c0.1-0.2,0.2-0.4,0.2-0.6
                                c0-0.3-0.1-0.6-0.3-0.8C12.9,15.9,12.6,15.8,12.3,15.8z M12,14.2H3c-0.1,0-0.3-0.1-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.4
                                c0-0.1,0.1-0.3,0.1-0.4c0.1-0.1,0.2-0.1,0.3-0.1h9c0.4,0,0.8-0.2,1.2-0.4c0.3-0.3,0.6-0.7,0.6-1.1l0.1-0.7H3.6
                                c-0.5,0-1-0.2-1.4-0.5C1.8,10,1.6,9.6,1.5,9L0.9,3.9c0-0.1,0-0.1,0-0.2C0.9,3.6,1,3.6,1,3.5c0-0.1,0.1-0.1,0.2-0.1
                                c0.1,0,0.1,0,0.2,0h14l0.4-2c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.1,0.3-0.1h2.3c0.1,0,0.3,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.1,0.4
                                c0,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.2,0.2-0.3,0.2h-1.9l-1.9,9.9c-0.1,0.7-0.5,1.3-1,1.7C13.3,14,12.6,14.2,12,14.2z M3.6,9.9h10.6
                                l1.1-5.5H1.9l0.5,4.5c0,0.3,0.2,0.5,0.4,0.7C3,9.8,3.3,9.9,3.6,9.9L3.6,9.9z"
                  />
                </svg>
                <span
                  v-if="countProducts"
                  :class="countProducts ? 'basket-count' : 'no-item'"
                  >{{ countProducts }}</span
                >
              </router-link>
            </div>
            <ul class="header-menu display--flex">
              <li>
                <router-link to="/products/all">Продукты</router-link>
              </li>
              <li class="has-submenu">
                <router-link to="/contacts">О нас</router-link>
                <div class="submenu">
                  <ul>
                    <li>
                      <router-link to="/methodology">Методика</router-link>
                    </li>
                    <li><router-link to="/contacts">Кто мы</router-link></li>
                  </ul>
                </div>
              </li>
              <li><router-link to="/diagnostic">Диагностика</router-link></li>
              <li><router-link to="/geography">География</router-link></li>
              <li><router-link to="/media-center">Медиа-центр</router-link></li>
            </ul>
          </nav>

          <button
            type="button"
            @click="openMobileMenu"
            :class="{
              active: mobileOpen,
            }"
            class="mobile-toggle mobile-toggle-b"
            id="mobile-toggle"
          ></button>
        </div>
      </div>
    </div>

    <div v-if="mobileOpen" class="mobile-menu--wrap" id="mobile-menu">
      <nav class="mobile-menu">
        <ul>
          <li><router-link to="/products/all">Продукты</router-link></li>
          <li><router-link to="/contacts">О нас</router-link></li>
          <li><router-link to="/diagnostic">Диагностика</router-link></li>
          <li><router-link to="/geography">География</router-link></li>
          <li><router-link to="/media-center">Медиа-центр</router-link></li>
          <li><router-link to="/methodology">Методика</router-link></li>
        </ul>
      </nav>
      <div class="mobile-menu--item mobile-menu--account">
        <a :href="cabinetUrl" class="display--flex">
          <span>Мой кабинет</span>
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <path
              d="M12,3c-5,0-9,4-9,9s4,9,9,9s9-4,9-9S17,3,12,3z M7,18.2c0.9-1.9,2.8-3.2,5-3.2s4.1,1.3,5,3.2c-1.4,1.1-3.1,1.8-5,1.8
                        S8.4,19.3,7,18.2z M9,11c0-1.7,1.3-3,3-3s3,1.3,3,3s-1.3,3-3,3S9,12.7,9,11z M17.8,17.5c-0.8-1.5-2.1-2.6-3.6-3.2
                        c1.1-0.7,1.9-2,1.9-3.4c0-2.2-1.8-4-4-4C9.8,7,8,8.8,8,11c0,1.4,0.7,2.7,1.9,3.4C8.3,14.9,7,16,6.2,17.5C4.9,16.1,4,14.1,4,12
                        c0-4.4,3.6-8,8-8s8,3.6,8,8C20,14.1,19.1,16.1,17.8,17.5z"
            />
          </svg>
        </a>
        <router-link to="/cart" class="display--flex">
          <span>Корзина</span>
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 20 20"
            width="20px"
            height="24px"
            fill="currentColor"
          >
            <path
              d="M12.3,19.1c-0.4,0-0.8-0.1-1.1-0.4c-0.3-0.2-0.6-0.6-0.8-1c-0.2-0.4-0.2-0.8-0.1-1.2c0.1-0.4,0.3-0.8,0.6-1.1
                                c0.3-0.3,0.7-0.5,1.1-0.6c0.4-0.1,0.8,0,1.2,0.1c0.4,0.2,0.7,0.4,0.9,0.8c0.2,0.3,0.3,0.7,0.3,1.1h0l0,0.1c0,0.6-0.2,1.1-0.6,1.5
                                C13.4,18.9,12.9,19.1,12.3,19.1z M4.5,19.1c-0.4,0-0.8-0.1-1.1-0.4c-0.3-0.2-0.6-0.6-0.8-1S2.4,17,2.5,16.5
                                c0.1-0.4,0.3-0.8,0.6-1.1c0.3-0.3,0.7-0.5,1.1-0.6c0.4-0.1,0.8,0,1.2,0.1c0.4,0.2,0.7,0.4,0.9,0.8c0.2,0.4,0.3,0.8,0.3,1.2l0,0
                                c0,0.6-0.2,1.1-0.6,1.5C5.6,18.9,5.1,19.1,4.5,19.1z M4.5,15.8c-0.2,0-0.4,0.1-0.6,0.2s-0.3,0.3-0.4,0.5S3.4,17,3.5,17.2
                                c0,0.2,0.1,0.4,0.3,0.6c0.1,0.2,0.3,0.3,0.5,0.3c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.2,0.5-0.4c0.1-0.2,0.2-0.4,0.2-0.6
                                c0-0.3-0.1-0.6-0.3-0.8C5.1,15.9,4.8,15.8,4.5,15.8z M12.3,15.8c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.3,0.3-0.4,0.5s-0.1,0.4-0.1,0.7
                                c0,0.2,0.1,0.4,0.3,0.6c0.2,0.2,0.3,0.3,0.5,0.3c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.2,0.5-0.4c0.1-0.2,0.2-0.4,0.2-0.6
                                c0-0.3-0.1-0.6-0.3-0.8C12.9,15.9,12.6,15.8,12.3,15.8z M12,14.2H3c-0.1,0-0.3-0.1-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.4
                                c0-0.1,0.1-0.3,0.1-0.4c0.1-0.1,0.2-0.1,0.3-0.1h9c0.4,0,0.8-0.2,1.2-0.4c0.3-0.3,0.6-0.7,0.6-1.1l0.1-0.7H3.6
                                c-0.5,0-1-0.2-1.4-0.5C1.8,10,1.6,9.6,1.5,9L0.9,3.9c0-0.1,0-0.1,0-0.2C0.9,3.6,1,3.6,1,3.5c0-0.1,0.1-0.1,0.2-0.1
                                c0.1,0,0.1,0,0.2,0h14l0.4-2c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.1,0.3-0.1h2.3c0.1,0,0.3,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.1,0.4
                                c0,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.2,0.2-0.3,0.2h-1.9l-1.9,9.9c-0.1,0.7-0.5,1.3-1,1.7C13.3,14,12.6,14.2,12,14.2z M3.6,9.9h10.6
                                l1.1-5.5H1.9l0.5,4.5c0,0.3,0.2,0.5,0.4,0.7C3,9.8,3.3,9.9,3.6,9.9L3.6,9.9z"
            />
          </svg>
        </router-link>
      </div>
      <div class="mobile-menu--item mobile-menu--contacts">
        <a href="tel:74959717302" class="display--flex">
          <span>+7 (495) 971-73-02</span>
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <path
              d="M5.2,20.6c-0.5,0-0.9-0.2-1.2-0.5c-0.3-0.3-0.5-0.8-0.5-1.3l0-3.3c0-0.5,0.2-0.9,0.5-1.3c0.3-0.3,0.8-0.5,1.2-0.5l3.2-0.1
                    c0.5,0,1,0.2,1.4,0.6l1.3,1.4c1.2-0.7,3.9-3.4,4.6-4.6l-1.3-1.1c-0.4-0.3-0.6-0.9-0.6-1.4l0.1-3.3c0-0.5,0.2-0.9,0.5-1.2
                    c0.3-0.3,0.8-0.5,1.2-0.5l3.4,0c0.5,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.8,0.5,1.2v1.7c0,5.6-7.1,13.6-13.7,13.7L5.2,20.6
                    C5.2,20.6,5.2,20.6,5.2,20.6z M8.4,14.8C8.4,14.8,8.4,14.8,8.4,14.8l-3.2,0.1c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.4
                    l0,3.3c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.2,0.4,0.2h1.7c5.7-0.1,12.5-7.7,12.5-12.5V5.2c0-0.2-0.1-0.3-0.2-0.4
                    c-0.1-0.1-0.2-0.2-0.4-0.2l-3.4,0c-0.1,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.4l-0.1,3.3c0,0.2,0.1,0.3,0.2,0.4l2,1.7L16.9,11
                    c-0.4,1.4-4.5,5.5-5.9,5.9L10.6,17l-1.8-2C8.7,14.9,8.5,14.8,8.4,14.8z"
            />
          </svg>
        </a>
        <a href="mailto:connect@eilab.ru" class="display--flex">
          <span>connect@eilab.ru</span>
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 24 24"
            width="24px"
            height="24px"
            fill="none"
            stroke="currentColor"
            stroke-width="1"
          >
            <path
              d="M5.7,6.9h12.6c1.1,0,2,0.9,2,2v6.9c0,1.1-0.9,2-2,2H5.7c-1.1,0-2-0.9-2-2V8.9C3.6,7.8,4.6,6.9,5.7,6.9z"
            />
            <path d="M19.7,7.5l-4.1,3.8c-2.1,2-5.4,2-7.5,0l-4-3.8" />
            <path d="M4.1,17.1l4.8-4.8 M19.8,17.2l-5.1-5.1" />
          </svg>
        </a>
      </div>
    </div>
  </header>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useRoute } from "vue-router";
import { computed, onMounted, watch } from "@vue/runtime-core";
import { useStore } from "vuex";

export default {
  name: "header-component",

  setup() {
    const route = useRoute(),
      store = useStore();
    let products = ref(null),
      countProducts = ref(0),
      whiteHeader = ref(route.path == "/" ? true : false),
      mobileOpen = ref(false),
      fixedMenuState = ref(false);

    let generalTags = computed(() => store.state.products.generalTags);

    let openMobileMenu = () => {
      mobileOpen.value = !mobileOpen.value;
      document.body.classList.toggle("menu-is-open");
    };
    let fixedHeader = () => {
      if (document.documentElement.scrollTop > 96) {
        fixedMenuState.value = true;
      } else {
        fixedMenuState.value = false;
      }
    };
    let cabinetUrl = process.env.VUE_APP_LK_BACK;

    onMounted(() => {
      window.addEventListener("scroll", fixedHeader);
    });

    const clickOnSpecial = () => {
      document.getElementById("specialButton").click();
    };

    watch(
      () => store.state.products.basketProducts,
      () => {
        countProducts.value = store.state.products.basketProducts;
      }
    );

    watch(
      () => route.path,
      (newPath) => {
        document.getElementsByTagName("html")[0].style.overflow = "unset";
        if (newPath == "/") {
          whiteHeader.value = true;
        } else {
          whiteHeader.value = false;
        }
        document.body.classList.remove("menu-is-open");
        mobileOpen.value = false;
      }
    );

    return {
      products,
      countProducts,
      whiteHeader,
      mobileOpen,
      openMobileMenu,
      generalTags,
      fixedHeader,
      fixedMenuState,
      clickOnSpecial,
      cabinetUrl,
    };
  },
};
</script>
<style scoped lang="scss">
.mobile-special,
.not-mobile-special {
  width: 28px;
  height: 28px;
  object-fit: cover;
  object-position: center;
  margin-right: 10px;
}

.not-mobile-special {
  @media (max-width: 768px) {
    visibility: hidden;
    opacity: 0;
  }
}

.mobile-special {
  width: 25px;
  height: 36px;
  padding-bottom: 11px;
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
}

.basket-count {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #65d03c;
  color: #ffffff;
  padding: 3px 5px;
  font-family: "Montserrat-regular";
  font-size: 10px;
  line-height: 1em;
  border-radius: 20px;
  top: -5px;
  left: -5px;
}

header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  padding-top: 35px;
  z-index: 10;
}

header .container {
  z-index: 2;
}

.header-inner {
  align-items: center;
  justify-content: space-between;

  &__fixed {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    width: 100%;
    height: 81px;
    box-shadow: 0px 4px 20px 0px #b6c4d680;

    li.has-submenu::before {
      border-color: var(--color) transparent transparent transparent !important;
    }

    a {
      color: var(--color) !important;
    }
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    @media (max-width: 767px) {
      padding-top: 15px;
      height: 53px;
      nav {
        display: none;
      }
    }
  }
}

.logo a,
.logo img {
  display: block;
}

header nav {
  flex-direction: column;
  align-items: flex-end;
}

header .account {
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 11px;
}

header .account a {
  position: relative;
  align-items: center;
  color: var(--color);
}

.header-index .account a {
  color: #ffffff;
}

header .account svg {
  width: 18px;
  height: 18px;
}

.account-link svg {
  margin-right: 4px;
}

.account-cart {
  margin-left: 20px;
}

.header-menu {
  align-items: center;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
  list-style: none;
}

.header-menu > li {
  position: relative;
  margin-left: 20px;
}

.header-menu > li > a {
  color: var(--color);
  font-size: 18px;
  line-height: 22px;
}

.header-menu > li.has-submenu {
  padding-right: 12px;
}

.header-menu > li.has-submenu::before {
  position: absolute;
  display: block;
  content: "";
  top: 50%;
  right: 0;
  width: 0;
  height: 0;
  border-width: 3px 3px 0 3px;
  border-style: solid;
  border-color: var(--color) transparent transparent transparent;
  transition: all 0.3s ease-in;
}

.header-menu > li .submenu {
  position: absolute;
  display: none;
  top: 100%;
  left: 0;
  margin: 0;
  padding: 12px 0 0 0;
}

.submenu ul {
  min-width: 120px;
  margin: 0;
  padding: 20px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(182, 196, 214, 0.5);
  list-style: none;
}

.submenu ul > li + li {
  margin-top: 12px;
}

.submenu ul > li > a {
  color: var(--color);
  font-size: 18px;
  line-height: 22px;
  white-space: nowrap;
}

.submenu ul > li:hover > a,
.submenu ul > li.active > a {
  color: var(--accent) !important;
}

.header-index .header-menu > li > a {
  color: #ffffff;
}

.header-menu > li:hover .submenu {
  display: block;
}

.header-index .header-menu > li.has-submenu::before {
  border-color: #ffffff transparent transparent transparent;
}

.header-menu > li:hover > a,
.header-menu > li.active > a {
  color: var(--accent) !important;
}

.header-menu > li.has-submenu:hover::before,
.header-menu > li.has-submenu.active::before {
  border-color: var(--accent) transparent transparent transparent !important;
}

.mobile-btns {
  display: none;
}

@media (min-width: 768px) {
  .mobile-toggle {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .mobile-btns {
    display: flex;
  }
  .header-inner > nav {
    display: none !important;
  }

  header {
    padding-top: 15px;
  }

  .header-index {
    padding-top: 23px;
  }

  .logo img {
    max-width: 100px;
  }

  .menu-is-open header:not(.header-index) .for-index {
    display: block;
  }

  .menu-is-open header:not(.header-index) .for-other {
    display: none;
  }

  .mobile-toggle-b {
    background-image: url(./../../assets/images/icons/menu-icon-b.svg) !important;
  }

  .mobile-toggle {
    display: block;
    width: 44px;
    height: 44px;
    margin: -10px -10px 0 0;
    padding: 10px;
    background-color: transparent;
    background-image: url(./../../assets/images/icons/menu-icon.svg);
    background-repeat: no-repeat !important;
    background-position: center !important;
    border: none;
    box-shadow: none;
  }

  .mobile-toggle.active,
  header:not(.header-index) .mobile-toggle.active {
    background-image: url(./../../assets/images/icons/x-icon.svg);
  }

  .mobile-menu--wrap {
    position: absolute;
    display: flex;
    top: 0;
    right: 0;
    left: 0;
    flex-direction: column;
    justify-content: flex-start;
    height: 100vh;
    padding: 55px var(--xs-continer-gap);
    background: var(--color-dark);
    opacity: 0;
    overflow-y: auto;
    visibility: hidden;
    z-index: -1;
    transition: all 0.3s ease-in-out;
  }

  .mobile-menu--wrap {
    opacity: 1;
    visibility: visible;
    z-index: 1;
  }

  .mobile-menu--wrap a {
    color: #ffffff;
    text-decoration: none;
  }

  .mobile-menu--wrap a:hover,
  .mobile-menu--wrap a.active {
    opacity: 0.7;
  }

  .mobile-menu--wrap .logo {
    position: absolute;
    top: 19px;
  }

  .mobile-menu {
    padding: 35px 0;
  }

  .mobile-menu ul {
    margin: 0;
    padding: 0;
    font-size: 36px;
    line-height: 43px;
    text-align: left;
    list-style: none;
  }

  .mobile-menu ul li + li {
    margin-top: 12px;
  }

  .mobile-menu--item {
    padding: 35px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    font-size: 24px;
    line-height: 29px;
    text-align: left;
  }

  .mobile-menu--item a {
    align-items: center;
    justify-content: space-between;
  }

  .mobile-menu--item a svg {
    width: 36px;
    height: 36px;
  }

  .mobile-menu--item a + a {
    margin-top: 8px;
  }
}
</style>